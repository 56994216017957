<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Editace kategorie</h2>
    <div class="col-4 gap-2 d-flex flex-end flex-row-reverse">
      <button class="btn btn-secondary" @click="closeCategory">Zavřít</button>
      <button class="btn btn-danger" @click="deleteCategory">Smazat</button>
      <button class="btn btn-success" @click="updateCategory">Uložit</button>
    </div>
  </div>
  <div class="container mt-4 edit-area">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="lastName">Název<span class="required-field">*</span></label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          v-model="category.name"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  data() {
    return {
      category: {
        name: "",
      },
    };
  },
  mounted() {
    this.fetchCategory();
  },
  methods: {
    async fetchCategory() {
      try {
        const response = await api.get(`/categories/${this.$route.params.id}`); // Fetch single category
        this.category = response.data;
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    },
    async updateCategory() {
      try {
        await api.put(`/categories/${this.$route.params.id}`, this.category);
        this.$router.push("/admin/categories"); // Redirect to category list after update
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    async deleteCategory() {
      try {
        await api.delete(`/categories/${this.$route.params.id}`, this.category);
        this.$router.push("/admin/categories"); // Redirect to category list after update
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    closeCategory() {
      this.$router.push("/admin/categories");
    },
  },
};
</script>
