<template>
  <div class="top-calendar-row mt-5">
    <div class="row">
    <div class="filters row justify-content-around">
      <select
        v-model="selectedCategory"
        @change="fetchEntities(true)"
        class="form-select form-select-lg mb-2"
      >
        <option value="0">Všechny kategorie</option>
        <option
          v-for="category in categories"
          :key="category.id"
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>

      <select
        v-model="selectedEntity"
        @change="filterEvents(false)"
        class="form-select form-select-lg mb-2"
      >
        <option value="0">Všechny entity</option>
        <option
          v-for="entity in filteredEntities"
          :key="entity.id"
          :value="entity.id"
        >
          {{ entity.name }}
        </option>
      </select>
    </div>
    <div class="col-sm-12 mt-2" v-if="entity" v-html="entity.description || ''"></div>
  </div>

    <div class="calendar-buttons-actions">
      <button
        @click="createSimpleReservation"
        v-if="userCanEditSimpleReservations || simpleReservationAllowed"
        class="btn btn-success me-2"
      >
        Nová jednoduchá rezervace
      </button>
      <button
        @click="createReservation"
        class="btn btn-success"
        v-if="userCanMakeReservations || isAdmin"
      >
        Nová rezervace
      </button>
    </div>
  </div>
  <div class="calendar-container">
    <div class="calendar-sidebar">
      <FullCalendar
        ref="sidebarCalendar"
        :options="sidebarcalendarOptions"
        @dateClick="handleDateClick"
      />
    </div>
    <div class="calendar-main">
      <FullCalendar ref="mainCalendar" :options="calendarOptions">
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid"; // For month view
import timeGridPlugin from "@fullcalendar/timegrid"; // For week/day view
import interactionPlugin from "@fullcalendar/interaction"; // For date-click interaction
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import listPlugin from "@fullcalendar/list";
import api from "../services/api";

const formatDateTime = (date) => {
  //   const d = new Date(date);
  return date.slice(0, 16); // Return the date in "YYYY-MM-DDTHH:mm" format
};

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      selectedDate: null,
      selectedStart: null, // Store selected start time
      selectedEnd: null, // Store selected end time
      categories: [], // List of categories
      entities: [], // List of all entities
      filteredEntities: [], // Entities filtered by the selected category
      events: [], // All events (reservations)
      selectedCategory: this.$route.query.category_id || "0", // Currently selected category
      selectedEntity: "",
      simpleReservationAllowed: true,
      preselectedDate: this.$route.query.start_date
        ? new Date(this.$route.query.start_date)
        : null,
      entity: {
        description: '',
      }, // Currently selected entity
      closedPeriods: {},
      sidebarcalendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, bootstrap5Plugin],
        initialView: "dayGridMonth",
        weekends: true,
        selectable: true,
        initialDate: this.preselectedDate,
        dateClick: this.handleDateClick,
        themeSystem: "bootstrap5",
        dayHeaderFormat: {
          weekday: "narrow",
        },
        slotLabelFormat: [
          { month: "long", year: "numeric" }, // top level of text
          { weekday: "short" }, // lower level of text
        ],
        locale: "cs",
        height: 290,
        eventDisplay: "none",
        firstDay: 1,
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "prev,next", // will normally be on the right. if RTL, will be on the left
        },
      },
      isMobile: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          listPlugin,
        ],
        initialView: "timeGridWeek",
        weekends: true,
        // slotMinTime: "07:00:00",
        // slotMaxTime: "18:00:00",
        themeSystem: "bootstrap5",
        selectable: true,
        slotDuration: "01:00:00",
        headerToolbar: false,
        initialDate: this.preselectedDate,
        select: this.handleSelect,
        events: [],
        allDaySlot: false,
        height: window.matchMedia("(max-width: 768px)").matches ? 700 : '100%',
        eventMinHeight: 30,
        contentHeight: "auto",
        expandRows: true,
        locale: {
          code: "cs",
          noEventsText: "Žádné události k zobrazení"
        },
        firstDay: 1,
        businessHours: [
          // specify an array instead
          {
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
            startTime: "07:00", // 8am
            endTime: "18:00", // 6pm
          },
        ],
        nowIndicator: true,
        eventClick: this.handleEventClick,
        selectOverlap: function (event) {
          return event.display === "background";
        },
        // Modify event rendering based on SimpleReservation or not
        eventDidMount: (info) => {
          // Check if it's a SimpleReservation (based on your event property)
          if (info.event.extendedProps.isSimpleReservation) {
            // Set the background to grey and disable interaction
            info.el.style.backgroundColor = "#c9c9c9";
            if (!info.event.extendedProps.isCurrentUserAdmin) {
              info.el.style.pointerEvents = "none"; // Disable click for SimpleReservations
            }
            // Show only the user name from the event's extendedProps
            info.el.querySelector(".fc-event-title").textContent =
              info.event.extendedProps.userName;
          } else if (!info.event.extendedProps.approved) {
            info.el.style.opacity = "0.7";
          }
          if (info.event.extendedProps.description !== '') {
            info.el.addEventListener("mouseenter", (event) => {
                this.showDescriptionTooltip(event, info.event.extendedProps.description);
            });

            info.el.addEventListener("mouseleave", () => this.hideDescriptionTooltip());
          }
        },
        eventContent: (info) => {
          const title = document.createElement("div");
          title.classList.add("fc-event-title");
          title.innerHTML = `<strong>${info.event.title}</strong>`;

          const time = document.createElement("div");
          time.classList.add("fc-event-time");
          time.innerHTML = `${info.timeText}`;

          const description = document.createElement("div");
          description.classList.add("fc-event-description");
          description.innerHTML = `${
            info.event.extendedProps.description || ""
          }`;

          // Create a container to hold all elements
          const container = document.createElement("div");
          container.classList.add("fc-event-main-description");
          container.appendChild(title);
          container.appendChild(time);
          container.appendChild(description);

          return { domNodes: [container] }; // Return the container as DOM element
        },
      },
    };
  },
  mounted() {
    this.checkIfMobile(); // Check if the device is mobile and adjust the view
    window.addEventListener("resize", this.checkIfMobile); // Adjust on window resize
    this.restoreState();
    this.restoreState();
    this.fetchCategories();
    //this.fetchReservations();
    this.checkUserRole();
    this.addTooltipToNonBusinessHours();

    if (!this.selectedCategory)
      this.selectedCategory = this.$route.query.category_id || 0;
    this.fetchEntities();

    if (!this.selectedEntity) {
      this.selectedEntity = this.$route.query.entity_id || 0;
    }
    if (!this.preselectedDate)
      this.preselectedDate = this.$route.query.start_date
        ? new Date(this.$route.query.start_date)
        : null;

    this.filterEvents( true );
    if (this.preselectedDate) {
      console.log(this.preselectedDate);
      this.goToSpecificDate(this.preselectedDate);
    }

    // this.clearState();
  },
  methods: {
    addTooltipToNonBusinessHours() {
        document.body.addEventListener("mouseover", (event) => {
            if (event.target.classList.contains("fc-non-business")) {
                this.showTooltip(event);
            }
        });

        document.body.addEventListener("mouseout", (event) => {
            if (event.target.classList.contains("fc-non-business")) {
                this.hideTooltip();
            }
        });
    },

    showTooltip(event) {
        let tooltip = document.getElementById("non-business-tooltip");
        if (!tooltip) {
            tooltip = document.createElement("div");
            tooltip.id = "non-business-tooltip";
            tooltip.innerText = "Na tento čas není možné rezervovat.";
            document.body.appendChild(tooltip);
        }

        tooltip.style.position = "absolute";
        tooltip.style.background = "#333";
        tooltip.style.color = "white";
        tooltip.style.padding = "5px 10px";
        tooltip.style.fontSize = "12px";
        tooltip.style.borderRadius = "4px";
        tooltip.style.whiteSpace = "nowrap";
        tooltip.style.display = "block";
        tooltip.style.zIndex = "999";
        tooltip.style.top = `${event.clientY + 5}px`;
        tooltip.style.left = `${event.clientX + 5}px`;
    },

    hideTooltip() {
        const tooltip = document.getElementById("non-business-tooltip");
        if (tooltip) {
            tooltip.style.display = "none";
        }
    },

    showDescriptionTooltip(event, description) {
        let tooltip = document.getElementById("event-tooltip");
        if (!tooltip) {
            tooltip = document.createElement("div");
            tooltip.id = "event-tooltip";
            document.body.appendChild(tooltip);
        }
        if( description && description.replace(/<[^>]*>/g, '') ) {
          tooltip.innerHTML = description;
          tooltip.style.position = "absolute";
          tooltip.style.background = "#333";
          tooltip.style.color = "white";
          tooltip.style.padding = "8px 12px";
          tooltip.style.fontSize = "12px";
          tooltip.style.borderRadius = "6px";
          tooltip.style.whiteSpace = "normal"; // ✅ Allow multi-line wrapping
          tooltip.style.wordWrap = "break-word";
          tooltip.style.overflowWrap = "break-word";
          tooltip.style.maxWidth = "300px";
          tooltip.style.textAlign = "left";
          tooltip.style.zIndex = "1000";
          tooltip.style.display = "block";
          tooltip.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.2)";

          const tooltipPadding = 10;
          const tooltipHeight = tooltip.offsetHeight;
          const tooltipWidth = tooltip.offsetWidth;
          const viewportWidth = window.innerWidth;
          const viewportHeight = window.innerHeight;

          let top = event.clientY - tooltipHeight / 2; // Center vertically
          let left = event.clientX + tooltipPadding; // Position to the right

          // Adjust if tooltip goes off-screen
          if (top + tooltipHeight > viewportHeight) {
              top = viewportHeight - tooltipHeight - tooltipPadding; // Shift upwards
          }
          if (top < 0) {
              top = tooltipPadding; // Prevent going above screen
          }
          if (left + tooltipWidth > viewportWidth) {
              left = event.clientX - tooltipWidth - tooltipPadding; // Show to the left
          }

          tooltip.style.top = `${top}px`;
          tooltip.style.left = `${left}px`;
        }
    },

    hideDescriptionTooltip() {
        const tooltip = document.getElementById("event-tooltip");
        if (tooltip) {
            tooltip.style.display = "none";
        }
    },

    checkIfMobile() {
      this.isMobile = window.matchMedia("(max-width: 768px)").matches;
      const newView = this.isMobile ? "listWeek" : "timeGridWeek";
      // Only update if the view has changed
      if (this.calendarOptions.initialView !== newView) {

        this.calendarOptions.initialView = newView;

        if ( this.isMobile ) {

          this.calendarOptions.height = 700;
        } else {
          this.calendarOptions.height = '100%';
        }

        // Refresh the calendar
        this.$nextTick(() => {
          const calendarApi = this.$refs.mainCalendar.getApi();
          calendarApi.changeView(newView); // Change to the new view

          // calendarApi.refetchEvents();     // Optionally, refetch events if needed
        });
      }
    },
    saveState() {
      localStorage.setItem("selectedCategory", this.selectedCategory);
      localStorage.setItem("selectedEntity", this.selectedEntity);
      localStorage.setItem(
        "preselectedDate",
        this.preselectedDate ? this.preselectedDate.toISOString() : null
      );
    },
    restoreState() {
      const savedCategory = localStorage.getItem("selectedCategory");
      const savedEntity = localStorage.getItem("selectedEntity");
      const savedDate = localStorage.getItem("preselectedDate");

      if (savedCategory) this.selectedCategory = savedCategory;
      if (savedEntity) this.selectedEntity = savedEntity;
      if (savedDate && savedDate !== "null")
        this.preselectedDate = new Date(savedDate);

      // Now apply filters and go to the saved date
      // this.filterEvents();
      if (this.preselectedDate) {
        this.goToSpecificDate(this.preselectedDate);
      }
    },
    clearState() {
      // if( this.selectedEntity ) {
      //   this.selectedEntity = 0;
      // }
      localStorage.removeItem("selectedCategory");
      localStorage.removeItem("selectedEntity");
      localStorage.removeItem("preselectedDate");
    },
    checkUserRole() {
      const userRole = localStorage.getItem("userRole");
      if (userRole === "admin") {
        this.isAdmin = true; // Mark the user as admin if they have the admin role
      }
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.canEditEntityCategory = user.can_edit_entities_and_categories;
        this.userCanEditSimpleReservations = user.can_edit_simple_reservations;
        this.userCanSeeAvailableEntities = user.can_see_available_entities;
        this.userCanMakeReservations = user.can_make_reservations;
      }
    },
    goToSpecificDate(dateString) {
      // Use the FullCalendar instance reference
      const calendarApi = this.$refs.mainCalendar.getApi();
      calendarApi.gotoDate(dateString); // Navigate to the given date
      const calendarApi2 = this.$refs.sidebarCalendar.getApi();
      calendarApi2.gotoDate(dateString); // Navigate to the given date
    },
    // Fetch all categories
    async fetchCategories() {
      try {
        const response = await api.get("/categories");
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },

    // Fetch entities for the selected category
    async fetchEntities( clear=false ) {
      if (this.selectedCategory && this.selectedCategory != 0 ) {
        try {
          const response = await api.get(
            `/entities?category_id=${this.selectedCategory}&is_calendar=true`
          );
          this.filteredEntities = response.data;
          if ( ! localStorage.getItem("selectedEntity") && !this.selectedEntity ) {
            this.selectedEntity = 0;
          }
          if ( clear ) {
            this.selectedEntity = 0;
            this.clearState();
          }
          //  else {
          //   this.clearState();
          // }
          // this.selectedEntity = ""; // Reset entity selection
          this.filterEvents(); // Filter events again after category change
        } catch (error) {
          console.error("Error fetching entities:", error);
        }
      } else {
        this.filteredEntities = this.entities;
        this.selectedEntity = 0;
        if( this.entity ) {
          this.entity.description = '';
        }
        this.filterEvents();
      }
    },

    // Fetch all reservations
    async fetchReservations() {
      let filter_date = this.selectedDate ?? this.formatDate(new Date() );
      if ( localStorage.getItem("preselectedDate") && localStorage.getItem("preselectedDate") !== 'null' ) {
        filter_date = this.formatDate( localStorage.getItem("preselectedDate") );
        localStorage.removeItem("preselectedDate");
      }
      try {
        const response = await api.get("/reservations", {
          params: {
            category_id: this.selectedCategory,
            entity_id: this.selectedEntity,
            selected_date: filter_date,  // Send clicked date
          }
        });
        this.events = response.data.map((reservation) => ({
          title: reservation.name,
          start: reservation.start_time,
          end: reservation.end_time,
          entityId: reservation.entity.id, // Store the entity ID for filtering
          categoryId: reservation.entity.category_id, // Store the category ID for filtering
          color: reservation.color,
          id: reservation.id,
          isSimpleReservation: reservation.extendedProps.isSimpleReservation,
          userName: reservation.extendedProps.userName,
          description: reservation.extendedProps.description,
          overlap: reservation.entity.overlap,
          allowRegistration: reservation.extendedProps.allowRegistration,
          registrationCode: reservation.extendedProps.registrationCode,
          isCurrentUserAdmin: reservation.extendedProps.isCurrentUserAdmin,
          approved: reservation.extendedProps.approved,
          // display: "background",
        }));
        this.calendarOptions.events = this.events;
        //this.filterEvents();
      } catch (error) {
        console.error("Error fetching reservations:", error);
      }
    },

    // Filter events based on the selected entity
    filterEvents( skip_fetch = false ) {
      if ( ! skip_fetch ) {
        this.fetchReservations();
      }
      if (this.selectedCategory && this.selectedCategory != 0 && this.selectedEntity && this.selectedEntity != 0) {
        this.calendarOptions.events = this.events.filter(
          (event) => event.entityId === parseInt(this.selectedEntity)
        );
        this.entity = this.filteredEntities.filter(
          (entity) => entity.id === parseInt(this.selectedEntity)
        )[0];
        if (this.entity) {
          this.simpleReservationAllowed =
            this.entity.simple_reservations_allowed;
          this.userCanMakeReservations = this.entity.can_make_reservation;
          this.initializeCalendar(this.entity);
        }
      } else if( this.selectedCategory && this.selectedCategory != 0 ){
        this.calendarOptions.events = this.events.filter(
          (event) => event.categoryId === parseInt(this.selectedCategory)
        );
        this.entity.description = '';
        this.refreshCalendar( true );
      } else {
        this.calendarOptions.events = this.events; // Show all events if no entity is selected
        this.refreshCalendar();
      }
    },

    handleDateClick(info) {
      // When a date is clicked in the sidebar month calendar, go to that week in the main calendar
      const calendarApi = this.$refs.mainCalendar.getApi();
      calendarApi.gotoDate(info.date); // Navigate to the selected date's week
      this.preselectedDate = info.date;
      const clickedDate = this.formatDate(info.date);
      this.selectedDate = clickedDate;

      this.fetchReservations();

      // calendarApi.refetchEvents();
    },

    formatDate(date) {
        return new Date(date).toISOString().split("T")[0];
    },

    handleSelect(info) {
      const now = new Date();

      // Check if the selected start time is in the past
      if (info.start < now) {
        alert("Nemůžete vybrat čas v minulosti.");
        return;
      }
      var overlappingClosedPeriod = null;
      // Check if the selected time range overlaps with any closed periods
      if (this.closedPeriods.lenght) {
        overlappingClosedPeriod = this.closedPeriods.some((period) => {
          const periodStart = new Date(period.start);
          const periodEnd = new Date(period.end);

          return info.start < periodEnd && info.end > periodStart;
        });
      }

      if (overlappingClosedPeriod) {
        alert("Vybraný čas se překrývá s dobou, kdy je zavřeno.");
        return;
      }

      // Store the selected start and end times
      this.selectedStart = formatDateTime(info.startStr);
      this.selectedEnd = formatDateTime(info.endStr);
    },
    createSimpleReservation() {
      this.saveState();
      // Redirect to the Simple Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/simple-reservation",
        query: {
          start: this.selectedStart,
          end: this.selectedEnd,
          category: this.selectedCategory,
          entity: this.selectedEntity,
        },
      });
    },
    createReservation() {
      this.saveState();
      // Redirect to the Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/reservation",
        query: {
          start: this.selectedStart,
          end: this.selectedEnd,
          category: this.selectedCategory,
          entity: this.selectedEntity,
        },
      });
    },
    handleEventClick(info) {
      console.log(info.event.extendedProps.allowRegistration);
      if (
        ((info.event.extendedProps.isSimpleReservation &&
          !info.event.extendedProps.isCurrentUserAdmin) ||
          !info.event.id ||
          (!info.event.extendedProps.allowRegistration &&
            !info.event.extendedProps.isCurrentUserAdmin)) &&
        !this.isAdmin
      ) {
        // Do nothing for SimpleReservations, prevent action
        return;
      }

      this.hideDescriptionTooltip();

      this.saveState();

      if (
        info.event.extendedProps.registrationCode &&
        !this.isAdmin &&
        !info.event.extendedProps.isCurrentUserAdmin
      ) {
        this.$router.push(
          `/reservations/${info.event.extendedProps.registrationCode}/register`
        );
      } else if (
        info.event.extendedProps.isSimpleReservation &&
        info.event.extendedProps.isCurrentUserAdmin
      ) {
        const reservationId = info.event.id;
        this.$router.push(`/simple-reservation/${reservationId}`);
      } else {
        const reservationId = info.event.id;
        this.$router.push(`/reservations/${reservationId}`);
      }
    },

    // Parse business hours based on availability_start and availability_end
    getBusinessHours(entity) {
      if (!entity) {
        return [];
      }
      const businessHours = [];

      const dayMap = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 0,
      };

      let availability_start = JSON.parse(entity.availability_start) || [];
      let availability_end = JSON.parse(entity.availability_end) || [];

      for (const [day, startTime] of Object.entries(availability_start)) {
        if (startTime && availability_start[day]) {
          businessHours.push({
            daysOfWeek: [dayMap[day]], // map day string to FullCalendar daysOfWeek
            startTime: startTime,
            endTime: availability_end[day],
          });
        }
      }

      return businessHours;
    },

    // Add closed periods as background events to disable selection
    getClosedPeriods(entity) {
      if (entity && entity.closed_period) {
        return entity.closed_period.map((period) => ({
          start: period.start + "T00:00:00",
          end: period.end + "T23:59:59", // FullCalendar uses exclusive end dates, so this is the end of the closed period
          display: "background", // This will render the closed periods as background
          overlap: false, // This prevents other events from overlapping
          color: "red", // Optional: color for the background closed period
          rendering: "background",
          classNames: ["closed-period"],
        }));
      }
      return [];
    },
    getSelectConstraints(entity) {
      // Get business hours as a constraint
      const businessHours = this.getBusinessHours(entity).map((hours) => ({
        daysOfWeek: hours.daysOfWeek, // Specify the days of the week
        startTime: hours.startTime, // Business hour start
        endTime: hours.endTime, // Business hour end
        display: "background", // Show as background event (non-selectable)
        overlap: false, // Don't allow overlapping events in business hours
      }));

      // Get closed periods as a constraint
      const closedPeriods = this.getClosedPeriods(entity) || [];
      const closedPeriodsConstraint = closedPeriods.map((period) => ({
        start: period.start, // Closed period start
        end: period.end, // Closed period end
        display: "background", // Show as background event
        overlap: false, // Disable selecting time slots during closed periods
      }));

      // Combine both constraints
      return [...businessHours, ...closedPeriodsConstraint];
    },
    // Function to initialize the calendar options
    initializeCalendar(entity) {
      const businessHours = this.getBusinessHours(entity);
      this.closedPeriods = this.getClosedPeriods(entity) || [];

      const selectConstraints = this.closedPeriods.map((period) => ({
        start: period.start,
        end: period.end,
        display: "background",
        rendering: "background",
        color: "grey",
        overlap: false,
        classNames: ["closed-period"],
      }));

      const events = this.events.filter(
        (event) => event.entityId === parseInt(this.selectedEntity)
      );

      const combinedEvents = [...events, ...selectConstraints];

      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          listPlugin,
        ],
        initialView: "timeGridWeek",
        weekends: true,
        themeSystem: "bootstrap5",
        selectable: true,
        slotDuration: "01:00:00",
        headerToolbar: false,
        allDaySlot: false,
        height: window.matchMedia("(max-width: 768px)").matches ? 700 : '100%',
        locale: {
          code: "cs",
          noEventsText: "Žádné události k zobrazení"
        },
        firstDay: 1,
        businessHours: businessHours, // Set dynamically based on entity's availability
        events: combinedEvents, // Closed periods set as background events
        nowIndicator: true,
        eventClick: this.handleEventClick,
        select: this.handleSelect,
        selectConstraint: this.getSelectConstraints(entity),
        initialDate: this.preselectedDate,
        expandRows: true,
        selectOverlap: function (event) {
          return event.display !== "background";
        },
        // Modify event rendering based on SimpleReservation or not
        eventDidMount: (info) => {
          // Check if it's a SimpleReservation (based on your event property)
          if (info.event.extendedProps.isSimpleReservation) {
            // Set the background to grey and disable interaction
            info.el.style.backgroundColor = "#c9c9c9";
            if (!info.event.extendedProps.isCurrentUserAdmin) {
              info.el.style.pointerEvents = "none"; // Disable click for SimpleReservations
            }
            // Show only the user name from the event's extendedProps
            info.el.querySelector(".fc-event-title").textContent =
              info.event.extendedProps.userName;
          } else if (!info.event.extendedProps.approved) {
            info.el.style.opacity = "0.7";
          }
          if (info.event.extendedProps.description) {
            info.el.addEventListener("mouseenter", (event) => {
                this.showDescriptionTooltip(event, info.event.extendedProps.description);
            });

            info.el.addEventListener("mouseleave", () => this.hideDescriptionTooltip());
          }
        },
        eventContent: (info) => {
          const title = document.createElement("div");
          title.classList.add("fc-event-title");
          title.innerHTML = `<strong>${
            info.event.title || info.event.extendedProps.userName || ""
          }</strong>`;

          const time = document.createElement("div");
          time.classList.add("fc-event-time");
          time.innerHTML = `${info.timeText}`;

          const description = document.createElement("div");
          description.classList.add("fc-event-description");
          description.innerHTML = `${
            info.event.extendedProps.description || ""
          }`;

          // Create a container to hold all elements
          const container = document.createElement("div");
          container.classList.add("fc-event-main-description");
          container.appendChild(title);
          container.appendChild(time);
          container.appendChild(description);

          return { domNodes: [container] }; // Return the container as DOM element
        },
      };
    },
    refreshCalendar( filter_event = false ) {
      this.closedPeriods = [];

      const selectConstraints = this.closedPeriods.map((period) => ({
        start: period.start,
        end: period.end,
        display: "background",
        rendering: "background",
        color: "grey",
        overlap: false,
        classNames: ["closed-period"],
      }));
      var events = [];
      if ( filter_event ) {
         events = this.calendarOptions.events;
      } else {
         events = this.events;
      }

      const combinedEvents = [...events, ...selectConstraints];

      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          listPlugin,
        ],
        initialView: "timeGridWeek",
        weekends: true,
        themeSystem: "bootstrap5",
        selectable: true,
        slotDuration: "01:00:00",
        headerToolbar: false,
        allDaySlot: false,
        height: window.matchMedia("(max-width: 768px)").matches ? 700 : '100%',
        expandRows: true,
        locale: {
          code: "cs",
          noEventsText: "Žádné události k zobrazení"
        },
        firstDay: 1,
        events: combinedEvents, // Closed periods set as background events
        nowIndicator: true,
        eventClick: this.handleEventClick,
        select: this.handleSelect,
        initialDate: this.preselectedDate,
        selectOverlap: function (event) {
          return event.display !== "background";
        },
        // Modify event rendering based on SimpleReservation or not
        eventDidMount: (info) => {
          // Check if it's a SimpleReservation (based on your event property)
          if (info.event.extendedProps.isSimpleReservation) {
            // Set the background to grey and disable interaction
            info.el.style.backgroundColor = "#c9c9c9";
            if (!info.event.extendedProps.isCurrentUserAdmin) {
              info.el.style.pointerEvents = "none"; // Disable click for SimpleReservations
            }
            // Show only the user name from the event's extendedProps
            info.el.querySelector(".fc-event-title").textContent =
              info.event.extendedProps.userName;
          } else if (!info.event.extendedProps.approved) {
            info.el.style.opacity = "0.7";
          }
          if (info.event.extendedProps.description !== '') {
            info.el.addEventListener("mouseenter", (event) => {
                this.showDescriptionTooltip(event, info.event.extendedProps.description);
            });

            info.el.addEventListener("mouseleave", () => this.hideDescriptionTooltip());
          }
        },
        eventContent: (info) => {
          const title = document.createElement("div");
          title.classList.add("fc-event-title");
          title.innerHTML = `<strong>${
            info.event.title || info.event.extendedProps.userName || ""
          }</strong>`;

          const time = document.createElement("div");
          time.classList.add("fc-event-time");
          time.innerHTML = `${info.timeText}`;

          const description = document.createElement("div");
          description.classList.add("fc-event-description");
          description.innerHTML = `${
            info.event.extendedProps.description || ""
          }`;

          // Create a container to hold all elements
          const container = document.createElement("div");
          container.classList.add("fc-event-main-description");
          container.appendChild(title);
          container.appendChild(time);
          container.appendChild(description);

          return { domNodes: [container] }; // Return the container as DOM element
        },
      };
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkIfMobile);
  },
};
</script>

<style scoped>
/* Layout to show the small month calendar on the left and the weekly calendar on the right */
.calendar-container {
  display: flex;
  background-color: white;
  border-radius: 5px;
  min-height: calc(100vh - 200px);
}

.calendar-sidebar {
  width: 15%;
  padding: 10px;
}

.calendar-main {
  width: 80%;
  padding: 10px;
}

h3 {
  text-align: center;
  margin-bottom: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 10px;
}

.top-calendar-row {
  display: flex;
  justify-content: space-between;
}

.fc .fc-daygrid-day-frame {
  max-height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-sidebar .fc-theme-standard td {
  border: none;
}

.filters {
  flex-wrap: nowrap;
  gap: 10px;
}
.filters select {
  max-width: 300px;
}
@media (max-width: 1000px) {
  .calendar-container {
    flex-wrap: wrap;
    justify-content: start;
        gap: 0;
  }
  .calendar-sidebar .fc .fc-daygrid-day.fc-day-today {
    border-radius: 0;
  }
  .calendar-main{
    min-height: 300px;
  }
  .calendar-main,
  .calendar-sidebar {
    width: 100%;
  }
  .calendar-main{
    height: calc(100vh - 490px);
  }
  .calendar-sidebar{
    height: 290px;
  }
  .filters {
    flex-wrap: wrap;
  }
  .filters select {
    max-width: 100%;
  }
  .top-calendar-row {
    gap: 30px;
  }
  .calendar-buttons-actions {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
  }
}
</style>
<style>
.fc .fc-daygrid-day-frame {
  max-height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-sidebar .fc-theme-bootstrap5 th,
.calendar-sidebar .fc-theme-bootstrap5 .fc-scrollgrid,
.calendar-sidebar .fc-dayGridMonth-view td {
  border: none;
}
.fc .fc-daygrid-day.fc-day-today,
.fc .fc-daygrid-day-bg .fc-highlight {
  border-radius: 50%;
}
.fc .fc-timegrid-slot {
  height: 2.2em;
}
.btn.btn-primary.fc-next-button,
.btn.btn-primary.fc-prev-button {
  background: none;
  border: none;
  color: black;
}
.fc .fc-daygrid-day-number {
  font-size: 10px;
}
.fc-timegrid-event .fc-event-main {
  overflow: hidden;
}
.fc-event-main-description {
  overflow-y: auto;
  max-height: 100%;
}
#event-tooltip *:not(a){
  color: white!important;
}
@media (max-width: 1000px) {
  .calendar-sidebar .fc .fc-daygrid-day.fc-day-today {
    border-radius: 0;
  }
}
</style>
