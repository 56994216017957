<template>
  <div class="container">
    <div class="row mt-5 mb-2">
      <div class="d-flex mb-3 justify-content-between page-heading p-0">
        <div class="row">
          <div
            class="col-sm-12 d-flex gap-2 justify-content-between cat-ent-select-wrap"
          >
            <select
              v-model="selectedCategory"
              class="form-select form-select-lg"
              @change="fetchEntities"
              disabled
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
            <select
              v-model="reservation.entity_id"
              class="form-select form-select-lg"
              disabled
            >
              <option
                v-for="entity in entities"
                :key="entity.id"
                :value="entity.id"
              >
                {{ entity.name }}
              </option>
            </select>
          </div>

          <div
            class="col-sm-12 mt-2"
            v-html="reservation.entity.description"
          ></div>
        </div>
        <!-- Buttons -->
        <div class="col-md-3 text-end">
          <button
            class="btn btn-secondary me-2"
            @click="closeForm"
            v-if="isAuthenticated"
          >
            Zavřít
          </button>
          <button
            class="btn btn-success"
            @click="register"
            :disabled="isRegisted"
          >
            {{
              reservation.current_user_status.is_in_waitlist ||
              reservation.current_user_status.is_in_approved
                ? "Odhlásit"
                : "Registrovat"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container edit-area small-button-text">
    <!-- Reservation Information -->
    <div class="row mt-3">
      <div class="col-md-6">
        <div>
          <label for="name">Název</label>
          <input
            v-model="reservation.name"
            type="text"
            class="form-control"
            readonly
            disabled
          />
        </div>
        <div>
          <label for="description">Popis</label>
          <div
            class="form-control description-disabled"
            v-html="reservation.description"
          ></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="start_time">Začátek rezervace (DD.MM.YYYY HH:MM)</label>
            <input
              v-model="reservation.start_time"
              type="datetime-local"
              class="form-control"
              readonly
              disabled
            />
          </div>
          <div class="col-md-6">
            <label for="end_time">Konec rezervace (DD.MM.YYYY HH:MM)</label>
            <input
              v-model="reservation.end_time"
              type="datetime-local"
              class="form-control"
              readonly
              disabled
            />
          </div>
        </div>
        <div class="row" v-if="reservation.capacity">
          <div class="col-md-5">
            <label for="capacity">Zbývajíci kapacita/Celková kapacita</label>
            <input
              :value="`${reservation.capacity - approvedUsers}/${reservation.capacity}`"
              type="text"
              class="form-control w-50"
              readonly
              disabled
            />
          </div>
        </div>
        <div
          class="row"
          v-if="
            reservation.registration_allowed &&
            (reservation.registration_start_time ||
              reservation.registration_end_time)
          "
        >
          <div class="col-md-6">
            <label for="registration_start_time"
              >Začátek registrace (DD.MM.YYYY)</label
            >
            <input
              type="datetime-local"
              v-model="reservation.registration_start_time"
              class="form-control"
              readonly
              disabled
            />
          </div>
          <div class="col-md-6">
            <label for="registration_end_time"
              >Konec registrace (DD.MM.YYYY)</label
            >
            <input
              type="datetime-local"
              v-model="reservation.registration_end_time"
              class="form-control"
              readonly
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="admin_emails">Správci</label>
            <div
              v-for="admin in reservation.administrators"
              :key="admin.email"
              class="admin-email"
            >
              <a :href="`mailto:${admin.email}`">{{ admin.name }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row" v-if="!isAuthenticated">
          <div class="col-md-4">
            <label for="email">E-mail<span class="required-field">*</span></label>
            <input v-model="guest.email" type="email" class="form-control" required/>
          </div>
          <div class="col-md-4">
            <label for="phone_number">Telefonní číslo<span class="required-field">*</span></label>
            <input
              v-model="guest.phone_number"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="col-md-4">
            <label for="name">Jméno<span class="required-field">*</span></label>
            <input
              v-model="guest.name"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="message">Poznámka</label>
            <textarea
              v-model="message"
              class="form-control"
              rows="6"
              :readonly="
                reservation.current_user_status.is_in_waitlist ||
                reservation.current_user_status.is_in_approved
              "
              :disabled="
                reservation.current_user_status.is_in_waitlist ||
                reservation.current_user_status.is_in_approved
              "
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="errorModal"
    tabindex="-1"
    aria-labelledby="messageModalLabel"
    aria-hidden="true"
    ref="errorModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Display the dynamic message here -->
          <p>{{ modalInfoMessage }}</p>
          <p>{{ modalInfoAdditionalMessage }}</p>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="btn btn-secondary">Zavřít</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import { isLoggedIn } from "../keycloak";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      modalInfoMessage: "",
      modalInfoAdditionalMessage: "",
      isAuthenticated: isLoggedIn(),
      categories: [],
      entities: [],
      selectedCategory: null,
      selectedEntity: null,
      message: "",
      isRegisted: false,
      approvedUsers: 0,
      reservation: {
        name: "",
        description: "",
        start_time: "",
        end_time: "",
        capacity: 5,
        entity_id: null,
        entity: {
          description: "",
        },
        current_user_status: {
          is_in_waitlist: false,
          is_in_approved: false,
        },
      },
      guest: {
        email: "",
        phone_number: "",
        name: "",
        message: "",
      },
      reservationAdmins: [],
    };
  },
  methods: {
    validateReservation() {
      const errors = [];

      if( !this.isAuthenticated ) {
        if (!this.guest.email) {
          errors.push("E-mail je povinný.");
        }

        if (!this.guest.phone_number) {
          errors.push("Telefonní číslo je povinný.");
        }

        if (!this.guest.name) {
          errors.push("Jméno je povinný.");
        }
      }

      return errors;
    },
    openModal() {
      this.modalInstance = new Modal(this.$refs.errorModal, {});
      this.modalInstance.show();
    },

    // Close the message modal
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },
    fetchEntities() {
      // Fetch entities based on selected category
      api
        .get(`/categories/${this.selectedCategory}/entities`)
        .then((response) => {
          this.entities = response.data;
        });
    },
    register() {
      this.modalInfoAdditionalMessage = '';
      const validationErrors = this.validateReservation();

      if (validationErrors.length > 0) {
        // Show errors in a modal or as alerts
        this.modalInfoMessage = "Opravte prosím následující chyby:";
        this.modalInfoAdditionalMessage = validationErrors.join("\n");
        const modalEl = this.$refs.errorModal;
        this.modalInstance = Modal.getOrCreateInstance(modalEl);
        this.modalInstance.show();
        return;
      }
      // Register the guest for the reservation
      api
        .post(`/reservations/${this.$route.params.id}/register`, {
          email: this.guest.email,
          phone_number: this.guest.phone_number,
          name: this.guest.name,
          message: this.message,
        })
        .then((response) => {
          console.log(response);
          if (!isLoggedIn()) {
            this.isRegisted = true;
          } else {
            this.reservation.current_user_status.is_in_waitlist = true;
          }
          this.modalInfoMessage = response.data.message;
          this.openModal();
          if (!response.data.user) {
            this.isRegisted = false;
            this.reservation.current_user_status.is_in_waitlist = false;
          }
        })
        .catch((error) => {
          this.modalInfoMessage = error.response.data.message;
          this.openModal();
        });
    },
    closeForm() {
      this.$router.push("/reservations");
    },
    async fetchReservation() {
      try {
        const response = await api.get(
          `/reservations-details/${this.$route.params.id}`
        );
        this.reservation = response.data;
        if (
          response.data.registered_users &&
          response.data.current_user_status.current_user_id
        ) {
          let registered_users = response.data.registered_users.filter(
            (user) =>
              user.user_id == response.data.current_user_status.current_user_id
          );
          if (registered_users.length) {
            this.message = registered_users[0].notes;
          }
        }
        if (response.data.approved_users) {
          this.approvedUsers = response.data.approved_users.length;
        }
        this.selectedCategory = response.data.entity.category_id;
        this.categories.push(response.data.entity.category);
        this.fetchEntities();
        this.reservation.entity_id = response.data.entity.id;
      } catch (error) {
        console.error("Error fetching reservation:", error);
      }
    },
  },
  mounted() {
    this.fetchReservation();
  },
  created() {
    // Fetch categories and populate the dropdown
    api.get("/categories").then((response) => {
      this.categories = response.data;
    });
  },
};
</script>

<style scoped>
.admin-email {
  margin-bottom: 5px;
}
.description-disabled {
  background-color: var(--bs-secondary-bg);
}
</style>
