<template>
  <div class="container">
    <div class="row mt-5 mb-2">
      <div class="d-flex mb-3 justify-content-between page-heading">
        <div class="row">
        <div class="col-12 col-sm-5 d-flex gap-2 justify-content-between cat-ent-select-wrap">
          <select
            v-model="selectedCategory"
            class="form-select"
            @change="fetchEntities"
            required
          >
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
              :selected="selectedCategory == category.id"
            >
              {{ category.name }}
            </option>
          </select>

          <select v-model="reservation.entity_id" class="form-select" @change="selectEntity" required>
            <option
              v-for="entity in filteredEntities"
              :key="entity.id"
              :value="entity.id"
              :selected="selectedEntity == entity.id"
            >
              {{ entity.name }}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-12 ms-2" v-if="entity" v-html="entity.description ||  ''"></div>
      </div>
        <!-- Buttons -->
        <div class="col-12 col-sm-4 text-end">
          <button class="btn btn-secondary me-2" @click="closeForm">
            Zavřít
          </button>
          <button class="btn btn-danger me-2" @click="deleteReservation">
            Smazat
          </button>
          <button class="btn btn-success" @click="saveReservation">
            Uložit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-2 edit-area small-button-text">
    <!-- Main Form: Columns -->
    <div class="row d-flex flex-row">
      <div class="row">
        <div class="col-md-4">
          <label for="start_time">Začátek rezervace (DD.MM.YYYY HH:MM)<span class="required-field">*</span></label>
          <input
            type="datetime-local"
            v-model="reservation.start_time"
            class="form-control"
            :class="{ 'is-invalid': !reservation.start_time && validationTriggered }"
            required
          />
          <div class="invalid-feedback">Začátek rezervace je povinný.</div>

        </div>
        <div class="col-md-4">
          <label for="end_time">Konec rezervace (DD.MM.YYYY HH:MM)<span class="required-field">*</span></label>
          <input
            type="datetime-local"
            v-model="reservation.end_time"
            class="form-control"
            :class="{ 'is-invalid': !reservation.end_time && validationTriggered }"
            required
          />
          <div class="invalid-feedback">Konec rezervace je povinný.</div>
        </div>
        <div class="col-md-2">
          <label for="end_time">Tvůrce</label>
          <select
            v-model="reservation.user_id"
            class="form-select"
            :disabled="!isAdmin"
          >
            <option v-for="user in allUsers" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="errorModal"
    tabindex="-1"
    aria-labelledby="messageModalLabel"
    aria-hidden="true"
    ref="errorModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Display the dynamic message here -->
          <p>{{ modalInfoMessage }}</p>
          <p>{{ modalInfoAdditionalMessage }}</p>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="btn btn-secondary">Zavřít</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Overlapping Reservations Modal -->
  <div
      v-if="overlapConflicts.length > 0"
      class="modal fade"
      id="overlapModal"
      tabindex="-1"
      aria-labelledby="overlapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="overlapModalLabel">
              Bylo zjištěno překrývání
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="clearOverlap"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Rezervace se překrývá s těmito jinými rezervacemi:</p>
            <ul>
              <li v-for="conflict in overlapConflicts" :key="conflict.id">
                {{ conflict.conflict_reason || conflict.type === 'simple' && ! isAdmin ? 'Obsazeno' : conflict.name }} ({{ conflict.start_time }} -
                {{ conflict.end_time }})
              </li>
            </ul>
            <p>Chtěli byste:</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="saveAllowedReservations">
              Uložit pouze tam, kde je to možné
            </button>
            <button class="btn btn-secondary" @click="clearOverlap">
              Zrušit
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import api from "../services/api";
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      modalInstance: null,
      modalInfoMessage: "",
      modalInfoAdditionalMessage: "",
      categories: [],
      filteredEntities: {
        description: ""
      },
      entity: {
        description: "",
      },
      overlapConflicts: [],
      selectedCategory: this.$route.query.category || null,
      selectedEntity: null,
      allUsers: [],
      isAdmin: false,
      reservation: {
        id: null,
        start_time: this.$route.query.start || "",
        end_time: this.$route.query.end || "",
        entity_id: this.$route.query.entity || null,
        entity: {
          description: "",
        },
        user_id: null,
      },
    };
  },
  methods: {
    validateReservation() {
      const errors = [];

      if (!this.reservation.start_time) {
        errors.push("Začátek rezervace je povinný.");
      }

      if (!this.reservation.end_time) {
        errors.push("Konec rezervace je povinný.");
      }

      if (new Date(this.reservation.start_time) >= new Date(this.reservation.end_time)) {
        errors.push("Čas začátku musí být před časem konce.");
      }

      if (!this.reservation.entity_id) {
        errors.push("Musíte vybrat entitu.");
      }

      if (!this.isAdmin && !this.reservation.user_id) {
        errors.push("Tvůrce rezervace je povinný.");
      }

      return errors;
    },
    selectEntity(){
      this.entity = this.filteredEntities.filter(
          (entity) => entity.id === parseInt(this.reservation.entity_id)
        )[0];
    },
    openModal() {
      this.modalInstance = new Modal(this.$refs.errorModal, {});
      this.modalInstance.show();
    },

    async saveReservation() {
      this.clearOverlap();
      try {
        const validationErrors = this.validateReservation();

        if (validationErrors.length > 0) {
          // Show errors in a modal or as alerts
          this.modalInfoMessage = "Opravte prosím následující chyby:";
          this.modalInfoAdditionalMessage = validationErrors.join("\n");
          const modalEl = this.$refs.errorModal;
          this.modalInstance = Modal.getOrCreateInstance(modalEl);
          this.modalInstance.show();
          return;
        }
        if (this.reservation.id) {
          // Update existing reservation
          const response = await api.put(
            `/simple-reservations/${this.reservation.id}`,
            this.reservation
          );
          this.reservation.id = response.data.id;
          this.modalInfoMessage = "Rezervace byla úspěšně aktualizována";
          this.openModal();
        } else {
          // Create new reservation
          const response = await api.post(
            "/simple-reservations",
            this.reservation
          );
          this.reservation.id = response.data.id; // Set the newly created reservation's ID
          this.modalInfoMessage = "Rezervace byla úspěšně vytvořena";
          this.openModal();
        }
        this.$router.push(`/simple-reservation/${this.reservation.id}`);
      } catch (error) {
        if (error.response && error.response.data.overlapping_reservations) {
          this.overlapConflicts = error.response.data.overlapping_reservations;
          // Access Bootstrap Modal
          this.$nextTick(() => {
            const modalElement = document.getElementById("overlapModal");
            this.modal = new Modal(modalElement); // Initialize the Bootstrap Modal instance
            this.modal.show(); // Show the modal
          });

          // const modal = new window.bootstrap.Modal(
          //   document.getElementById("overlapModal")
          // );
          // modal.show();
        } else {
          this.modalInfoMessage = error.response.data.message;
          if (error.response.data.available_hours) {
            this.modalInfoAdditionalMessage =
              error.response.data.available_hours;
          }
          this.openModal();

          this.errorMessage =
            error.response.data.message || "Chyba uložení rezervace";
        }
        
      }
    },
    async deleteReservation() {
      if (
        confirm(
          "Tato akce je nevratná! Po kliknutí na tlačítko OK se Rezervace smaže."
        )
      ) {
        try {
          await api.delete(`/simple-reservations/${this.reservation.id}`);
          alert("Rezervace byla smazána");
          this.$router.push("/reservations");
        } catch (error) {
          console.error("Nastala chyba při mazání rezervace:", error);
        }
      }
    },
    async fetchEntities() {
      try {
        const response = await api.get(
          `/entities?category_id=${this.selectedCategory}&sr=true`
        );
        this.filteredEntities = response.data;

        if( this.reservation.entity_id ) {
      this.selectEntity();
    }
        
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    },

    // Logic to close the form
    closeForm() {
      this.$router.push("/");
    },

    async fetchReservation() {
      if (this.$route.params.id) {
        try {
          const response = await api.get(
            `/simple-reservations/${this.$route.params.id}`
          );
          this.reservation = response.data;
          this.selectedCategory = response.data.entity.category_id;
          if (this.selectedCategory) {
            this.fetchEntities();
          }
          this.selectedEntity = response.data.entity_id;
          this.reservation.entity_id = response.data.entity.id;
          this.entity.description = response.data.entity.description;
          this.allUsers = [response.data.user];
        } catch (error) {
          console.error("Error fetching reservation:", error);
        }
      }
    },
    checkUserRole() {
      const userRole = localStorage.getItem("userRole");
      if (userRole === "admin") {
        this.isAdmin = true; // Mark the user as admin if they have the admin role
      }
    },
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async saveAllowedReservations() {
      try {
        // Send the reservation with non-overlapping data to the server
        const response = await api.post(
          `/simple-reservations/${this.reservation.id}/save-allowed`,
          this.reservation
        );
        console.log(response)
        alert("Rezervace byly uloženy tam, kde to bylo možné");

        // Hide the modal and clear conflicts
        this.clearOverlap();

        this.reservation.id = response.data.id; // Set the newly created reservation's ID
        this.reservation = response.data;
        this.$router.push(`/simple-reservation/${this.reservation.id}`);
      } catch (error) {
        if (error.response.data.message) {
          this.clearOverlap();
          this.openModal();
          this.modalInfoMessage = error.response.data.message;
        }
      }
    },

    clearOverlap() {
      // Clear the overlapping conflicts and close the modal
      this.overlapConflicts = [];
      this.modalInfoAdditionalMessage = "";
      if (this.modal) {
        this.modal.hide();
      }
    },
  },

  async mounted() {
    this.checkUserRole();
    // this.fetchEntities();

    try {
      const categoriesResponse = await api.get("/categories");
      this.categories = categoriesResponse.data;
      if (this.isAdmin) {
        const usersResponse = await api.get("/users");
        this.allUsers = usersResponse.data;
      } else {
        let user = localStorage.getItem("user");
        if (user) {
          user = JSON.parse(user);
          console.log(user);
          this.allUsers = [
            {
              id: user.id,
              name: user.name,
            },
          ];
          this.reservation.user_id = user.id;
        }

        this.fetchReservation();
      }
    } catch (error) {
      console.error("Error fetching data on mount:", error);
    }

    if (this.selectedCategory) {
      this.fetchEntities();
    }
    
  },
  created() {
    this.checkUserRole();
    this.fetchReservation();
  },
};
</script>
<style>
.cat-ent-select-wrap select {
  min-width: 250px;
}
@media ( max-width: 767px){
  .page-heading{
    flex-wrap: wrap;
  }
  .page-heading>div{
    flex-basis: 100%;
  }
  .page-heading>div.col-sm-4{
    justify-content: space-between!important;
  }
  .cat-ent-select-wrap select {
    min-width: 100px;
  }
}
</style>