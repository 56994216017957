<template>
  <div class="container">
    <div class="row justify-content-between mt-5 mb-2">
      <h2 class="col-6 col-md-3">
        {{ entityId ? "Editace entity" : "Vytvořit entitu" }}
      </h2>
      <div class="col-6 col-md-4 gap-2 d-flex flex-end justify-content-end">
        <button class="btn btn-secondary" @click="closeEntity">Zavřít</button>
        <button v-if="entityId" class="btn btn-danger" @click="deleteEntity">
          Smazat
        </button>
        <button class="btn btn-success" @click="saveEntity">Uložit</button>
      </div>
    </div>

    <div class="container mt-4 edit-area">
      <div class="row mb-3">
        <div class="col-md-7">
          <div class="form-group">
            <label for="name">Název<span class="required-field">*</span></label>
            <input
              type="text"
              v-model="entity.name"
              id="name"
              class="form-control"
              required
            />
          </div>

          <div class="form-group">
            <label for="description">Popis</label>
            
            <QuillEditor v-model:content="entity.description" contentType="html" theme="snow" />
          </div>

          <div class="row mt-2">
            <div class="col-md-6">
              <h4>Otevřeno pro rezervace<span class="required-field">*</span></h4>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-4">Od (HH:MM)</div>
                <div class="col-md-4">Do (HH:MM)</div>
              </div>
              <div
                v-for="(day, index) in daysOfWeek"
                :key="index"
                class="form-group mt-2"
              >
                <div class="row">
                  <div class="col-md-3">
                    <label>{{ day }}</label>
                  </div>
                  <div class="col-md-4">
                    <input
                      type="time"
                      v-model="entity.availability_start[index]"
                      class="form-control"
                      format="HH:mm"
                    />
                  </div>
                  <div class="col-md-4">
                    <input
                      type="time"
                      v-model="entity.availability_end[index]"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <label class="form-check-label">Aktivní<span class="required-field">*</span></label>

                  <div class="form-switch fs-4">
                    <input
                      type="checkbox"
                      v-model="entity.active"
                      class="form-check-input"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="form-check-label">Jednoduché rezervace<span class="required-field">*</span></label>

                  <div class="form-switch fs-4">
                    <input
                      type="checkbox"
                      v-model="entity.simple_reservations_allowed"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="form-check-label">Podléhá schvalování<span class="required-field">*</span></label>

                  <div class="form-switch fs-4">
                    <input
                      type="checkbox"
                      v-model="entity.approval_required"
                      class="form-check-input"
                    />
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="form-check-label">Překrývání času<span class="required-field">*</span></label>

                  <div class="form-switch fs-4">
                    <input
                      type="checkbox"
                      v-model="entity.time_overlap"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="category">Kategorie<span class="required-field">*</span></label>
                <select
                  v-model="entity.category_id"
                  id="category"
                  class="form-select"
                >
                  <option
                    v-for="category in categories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="email">E-mail</label>
                <input
                  type="email"
                  v-model="entity.email"
                  id="email"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h4>Zavřeno</h4>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6">Od (DD.MM.YYYY)</div>
              <div class="col-md-6">Do (DD.MM.YYYY)</div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <input
                  type="date"
                  v-model="newClosedPeriod.start"
                  class="form-control mb-2"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="date"
                  v-model="newClosedPeriod.end"
                  class="form-control mb-2"
                />
              </div>
            </div>
            <div class="row">
              <div class="d-flex justify-content-end">
                <button @click="addClosedPeriod" class="btn btn-success mb-2">
                  Přidat
                </button>
              </div>
            </div>
          </div>

          <ul v-if="entity.closed_period">
            <li v-for="(period, index) in entity.closed_period" :key="index">

              <button
                @click="removeClosedPeriod(index)"
                type="button" class="delete-icon" aria-label="Delete"
              >✖️
              </button>
              {{ period.start }} - {{ period.end }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'

import api from "@/services/api"; // Assuming Axios is set up for API calls

export default {
  components: {
    QuillEditor,
  },
  data() {
    return {
      entityId: null,
      entity: {
        name: "",
        description: "",
        category_id: null,
        availability_start: {
          monday: "01:00",
          tuesday: "01:00",
          wednesday: "01:00",
          thursday: "01:00",
          friday: "01:00",
          saturday: "01:00",
          sunday: "01:00",
        },
        availability_end: {
          monday: "23:00",
          tuesday: "23:00",
          wednesday: "23:00",
          thursday: "23:00",
          friday: "23:00",
          saturday: "23:00",
          sunday: "23:00",
        },
        active: true,
        approval_required: false,
        time_overlap: false,
        simple_reservations_allowed: false,
        email: "",
        closed_period: [],
      },
      categories: [],
      daysOfWeek: {
        monday: "Pondělí",
        tuesday: "Úterý",
        wednesday: "Středa",
        thursday: "Čtvrtek",
        friday: "Pátek",
        saturday: "Sobota",
        sunday: "Neděle",
      },
      newClosedPeriod: { start: "", end: "" },
    };
  },
  created() {
    this.fetchCategories();
    this.fetchEntity();
  },
  methods: {
    async fetchCategories() {
      const response = await api.get("/categories");
      this.categories = response.data;
    },
    async fetchEntity() {
      if (this.$route.params.id) {
        this.entityId = this.$route.params.id;
        const response = await api.get(`/entities/${this.entityId}`);
        this.entity = response.data;
      }
    },
    async saveEntity() {
      if (this.entityId) {
        await api.put(`/entities/${this.entityId}`, this.entity);
      } else {
        await api.post("/entities", this.entity);
      }
      this.$router.push("/admin/entities");
    },
    async deleteEntity() {
      if (this.entityId) {
        await api.delete(`/entities/${this.entityId}`, this.entity);
      }
      this.$router.push("/admin/entities");
    },
    closeEntity() {
      this.$router.push("/admin/entities");
    },
    addClosedPeriod() {
      if (this.newClosedPeriod.start && this.newClosedPeriod.end) {
        this.entity.closed_period.push({ ...this.newClosedPeriod });
        this.newClosedPeriod = { start: "", end: "" };
      }
    },
    removeClosedPeriod(index) {
      this.entity.closed_period.splice(index, 1);
    },
  },
};
</script>
<style>
@import "~quill/dist/quill.snow.css";

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  background-color: white;
}
.ql-editor{
    min-height:100px;
}
.ql-editor li{
  list-style-type: inherit;
}
</style>
<style scoped>
ul{
  list-style: none;
  padding-left: 0;
}
.delete-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: red; /* Make the X red */
  margin-left: 10px;
  padding: 0;
}

.delete-icon:hover {
  color: darkred; /* Darker red on hover for better visibility */
}

/* .btn-close{
  color: red;
} */
</style>
