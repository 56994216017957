<template>
  <div id="app">
    <header class="navbar navbar-expand-lg py-3">
      <div class="container">
        <router-link to="/" class="logo navbar-brand"
          ><img :src="image" alt="" />Rezervační systém</router-link
        >
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          v-if="isAuthenticated"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav nav-admin me-auto" role="navigation">
            <!-- Admin-specific links -->
            <li class="nav-item">
              <router-link to="/reservations" v-if="isAdmin" class="nav-link"
                >Rezervace</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/admin/users" v-if="isAdmin" class="nav-link"
                >Uživatelé</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/admin/roles" v-if="isAdmin" class="nav-link"
                >Nastavení práv</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/admin/entities"
                v-if="canEditEntityCategory"
                class="nav-link"
                >Entity</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                to="/admin/categories"
                v-if="canEditEntityCategory"
                class="nav-link"
                >Kategorie</router-link
              >
            </li>
          </ul>
          <div
            class="date-picker-container me-auto form-inline"
            v-if="isAuthenticated && userCanSeeAvailableEntities"
          >
            <Datepicker
              v-model="startDate"
              format="HH:mm dd-MM-yyyy"
              placeholder="Od (HH:MM DD.MM.YYYY)"
              :max-date="endDate"
            >
              <template #input-icon>
                <!--Empty to remove icon or place new SVG here -->
              </template>
            </Datepicker>
            <Datepicker
              v-model="endDate"
              format="HH:mm dd-MM-yyyy"
              placeholder="Do (HH:MM DD.MM.YYYY)"
              :min-date="startDate"
            >
              <template #input-icon>
                <!--Empty to remove icon or place new SVG here -->
              </template>
            </Datepicker>
            <button @click="filterEntitiesByDate" class="btn btn-success">
              Vyhledat volné entity
            </button>
          </div>
          <ul
            class="navbar-nav"
            v-if="isAuthenticated"
            role="navigation"
          >
            <li class="nav-item">
              <router-link
                v-if="!isAdmin"
                to="/my-reservations"
                class="nav-link"
                >Moje rezervace</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                v-if="!isAdmin"
                to="/my-registrations"
                class="nav-link"
                >Moje registrace</router-link
              >
            </li>
            <li class="nav-item user-name-logout">
              <strong>{{ userName }}</strong>,
              <a @click="logout" class="nav-link"> Odhlásit</a>
            </li>
            <!-- Calendar link -->
          </ul>
        </div>
      </div>
    </header>
    <div class="container">
      <router-view v-if="!isAuthenticated && !isHome"></router-view>
      <router-view v-if="keycloakReady && isAuthenticated"></router-view>
      <!-- Render components when Keycloak is ready -->
      <HomeView v-if="keycloakReady && !isAuthenticated && isHome" />
    </div>
  </div>
</template>

<script>
import { logout, isLoggedIn } from "./keycloak"; // Logout from Keycloak
import HomeView from "./components/HomePage.vue";
import image from "./assets/logo.png";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    Datepicker, // Register datetime picker component
    HomeView,
  },
  data() {
    return {
      keycloakReady: true, // Assume Keycloak is ready since it's initialized in main.js
      isAuthenticated: isLoggedIn(), // User's authentication state
      isAdmin: false, // Admin role check
      canEditEntity: false,
      canEditCategory: false,
      image: image,
      startDate: null, // Start date for filtering
      endDate: null, // End date for filtering
      isHome: this.$showHome,
      userName: '',
    };
  },
  created() {
    if (this.isAuthenticated) {
      this.checkUserRole();
    }
  },
  methods: {
    logout() {
      logout(); // Trigger the Keycloak logout process
    },
    checkUserRole() {
      const userRole = localStorage.getItem("userRole");
      if (userRole === "admin") {
        this.isAdmin = true; // Mark the user as admin if they have the admin role
      }
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        this.canEditEntityCategory = user.can_edit_entities_and_categories;
        this.userCanEditSimpleReservations = user.can_edit_simple_reservations;
        this.userCanSeeAvailableEntities = user.can_see_available_entities;
        this.userName = user.name ? user.name : user.username;
      }
    },
    formatDatetime(datetime) {
      const date = new Date(datetime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${hours}:${minutes} ${day}.${month}.${year}`; // Return formatted datetime
    },
    filterEntitiesByDate() {
      if (this.startDate && this.endDate) {
        const start = this.formatDatetime(this.startDate);
        const end = this.formatDatetime(this.endDate);

        // Redirect to the Entities page with the selected dates as query parameters
        this.$router.push({
          path: "/entities",
          query: { start: start, end: end },
        });
      } else {
        alert("Zadejte prosím začátek i konec.");
      }
    },
  },
};
</script>

<style>
* {
  font-size: 14px;
}
/* Basic styles for the app */
#app {
  /* text-align: center; */
  background-color: #f3f3f3;
  min-height: 100vh;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
}
header {
  background-color: #fff;
  color: #ababab;
  padding: 20px;
}
header .container {
  display: flex;
  justify-content: space-between;
}
nav {
  margin-top: 10px;
}
nav a {
  color: #ababab;
  margin: 0 10px;
  text-decoration: none;
}
nav a.router-link-active {
  font-weight: bold;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ababab;
  gap: 10px;
}

button {
  background-color: #5ba83e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 0px;
  border-radius: 5px;
  text-wrap: nowrap;
}
button:hover {
  background-color: #cc0000;
}
.date-picker-container {
  display: flex;
  gap: 20px;
}
@media (max-width: 992px) {
  .date-picker-container {
    flex-wrap: wrap;
  }
}
header .date-picker-container .dp__pointer {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}
h1 {
  font-size: 24px;
}
.edit-area {
  text-align: left;
  background-color: #f8e5e7;
  padding: 10px;
  min-height: 500px;
  border-radius: 5px;
  width: 100%;
}
.form-switch .form-check-input {
  height: 24px;
  width: 48px;
}
.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}
.form-switch .form-check-input:checked {
  background-color: #5ba93c;
  border-color: #5ba93c;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.container .btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5ba93c;
  --bs-btn-border-color: #5ba93c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5ba93c;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5ba93c;
  --bs-btn-active-border-color: #5ba93c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5ba93c;
  --bs-btn-disabled-border-color: #5ba93c;
}
.checkboxes-wrap {
  background-color: white;
  padding: 5px 5px 10px 5px;
  border-radius: 5px;
  max-height: 540px;
  min-height: 200px;
  overflow: auto;
  /* overflow-y: scroll; */
  /* height: calc(100% - 30px) */
}
.btn.btn-delete {
  color: red;
}
.checkboxes-wrap label {
  min-width: 85%;
  max-width: 85%;
  text-wrap: wrap;
  overflow-wrap: break-word;
  color: #8a8a8a;
}
.checkboxes-wrap a {
  color: #8a8a8a;
}
.small-button-text button {
  font-size: 12px;
}
.checkbox-lg .form-check-input {
  top: 0.8rem;
  scale: 1.4;
  margin-right: 0.7rem;
}
header.navbar.navbar-expand-lg .logo,
header.navbar.navbar-expand-lg .navbar-nav .nav-link {
  color: #ababab!important;
  font-size: 14px;
}
.required-field{
  color: red;
}
.user-name-logout{
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-name-logout a{
  cursor: pointer;
}
/* .container .form-check-input{
  border-color: grey;

}
.container .form-check-input:checked {
    background-color: #fff;
    border-color: grey;
}
.container .form-check-input:checked[type=checkbox] {
    --bs-form-check-bg-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27grey%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e);
} */
</style>
